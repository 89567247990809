body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
  width: 100%;
  border-color: black;
}

.landingPage {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30%;
  height: 100vh;
  overflow: hidden;
  transition: all;
  /* background-color: #FF914D; */
  background: linear-gradient(to bottom, #ff914d, #fc7787);
  padding-bottom: 2%;
}

.logo {
  height: 25%;
  /* background-color: #FF914D; */
  align-items: center;
  justify-content: center;
  width: 100%;
  align-content: center;
  justify-items: center;
  display: flex;
}

.logo > img {
  /* width: 40%;
  height: 70%; */
  min-height: 50%;
  max-height: 70%;
  max-width: 50%;
}

.logoSide {
  height: 30%;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-content: center;
  justify-items: center;
  display: flex;
}

.logoSide > img {
  width: 55%;
  height: 90%;
  margin-top: 20;
}

.login {
  margin-top: 2%;
  text-align: center;
  color: white;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 30;
}

.inputText {
  color: white;
  margin-left: 5%;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.inputTextModal {
  color: #ff914d;
  margin-left: 5%;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.inputTextField {
  border-radius: 10px;
  border-color: white;
  height: 40px;
  /* margin-left: 5%; */
  width: 100%;
  /* padding: 10px; */
  border-width: 0;
  padding-left: 10px;
}

.inputTextFieldModal {
  border-radius: 10px;
  border-color: white;
  height: 40px;
  margin-left: 5%;
  width: 90%;
  padding: 10px;
  border-width: 0;
  background-color: rgb(231, 240, 254);
}

.img-logo {
  margin-left: 200;
}

.user-type-radio label {
  display: block;
  margin-bottom: 3%;
  color: white;
  margin-left: 5%;
}

.user-type-heading {
  color: white;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 21;
  margin-top: 5%;
}

.user-type-radio input[type="radio"] {
  margin-right: 5px;
  vertical-align: middle;
}

.submitButton {
  justify-content: center;
  width: 90%;
  margin-top: 5%;
  align-items: center;
}

.loginButton {
  border-radius: 20px;
  border-color: white;
  height: 40px;
  width: 90%;
  justify-content: center;
  color: #ff914d;
  text-align: center;
  font-size: 20px;
  display: flex;
  align-self: center;
  border-width: 0;
  padding: 4px;
  margin: 15px;
  background-color: white;
}

.submitButtonModal {
  justify-content: center;
  width: 80%;
  margin-top: 1%;
  align-items: center;
  align-self: center;
  display: flex;
}

.loginButtonModal {
  border-radius: 20px;
  border-color: white;
  height: 40px;
  width: 70%;
  justify-content: center;
  color: white;
  font-size: 20px;
  display: flex;
  align-self: center;
  margin-left: 6%;
  border-width: 0;
  background-color: #ff914d;
  margin-top: 10;
  align-items: center;
}

.logoutButton {
  border-radius: 20px;
  border-color: white;
  height: 40px;
  width: 80%;
  justify-content: center;
  color: #ff914d;
  text-align: center;
  font-size: 20px;
  display: flex;
  align-self: center;
  margin-left: 6%;
  border-width: 0;
  margin: 10%;
}

.sideBarComponent {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  background: linear-gradient(to bottom, #ff914d, #fc7787);
  justify-content: space-between;
  position: absolute;
}

.dashboard-menu-container {
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.logoSideBarComponent {
  height: 100;
  width: 80%;
}

.logoSideBarComponent > img {
  width: 30%;
  height: 40%;
}

.userImages {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}

.userImagesModal {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-left: 5px;
}
.loanImagesModal {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-left: 5px;
}
.main {
  /* background-color: #FF914D; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main ul.menu li a {
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 15px;
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
}

.menu {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  height: 80%;
  width: 90%;

}

.main ul.menu li {
  list-style-type: none;
  margin-bottom: 5px;
  padding-left: 0px;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}

.header-container {
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  flex: 1;
}

.header-container-chat {
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
}

.dashboard {
  width: 76%;
  padding-top: 2%;
}

.dashboard h1 {
  margin: 1%;
  font-weight: bold;
  /* flex: 1; */
}

.dashboard-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-menu-box {
  display: flex;
  background: linear-gradient(to bottom, #ff914d, #fc7787);
  height: 96px;
  border-radius: 10px;
  margin: 5px;
  color: white;
  text-align: center;
  width: 20%;
  margin-right: 9%;
  margin-top: auto;
  padding-top: auto;
  padding-bottom: auto;
  margin-bottom: 4%;
  align-items: center;
  justify-content: center;
}

.dashboard-menu-box h1 {
  padding: 3%;
  font-size: medium;
}

.drivingLicenceImage {
  width: 150px;
  height: 150px;
  border-radius: 96px;
  margin-left: 30%;
}

.user-container {
  width: 77%;
  padding-top: 2%;
  margin-left: 22%;
}

.user-container h1 {
  font-weight: bold;
}

.user-container-chat {
  width: 30%;
  padding-top: 2%;
  background-color: yellow;
}

.user-search-container {
  display: flex;
  float: right;
}

.user-search-container button {
  margin-left: 5px;
  height: 38px;
}

.user-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.user-header {
  display: flex;
  align-items: center;
}

.inputTextUserSearchField {
  border-radius: 10px;
  height: 38px;
  margin-left: 5%;
  width: 80%;
  padding: 10px;
}

.content-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-section > img {
  width: 50%;
  height: 80vh;
  padding: 5%;
  align-self: center;
}

.search-button {
  display: flex;
  align-items: center;
}

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.user-table-data {
  border-radius: 5px;
  /* background-color: red; */
  /* max-height: 500px;
  min-height: 100px;
  overflow: auto; */
}

.user-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e7eaef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBrandButton {
  background-color: #ff914d;
  height: 40px;
  border-radius: 15px;
  border-color: white;
  color: white;
  float: right;
}

.user-logo > img {
  max-width: 70%;
  max-height: 70%;
  border-radius: 50%;
  /* Ensure the image remains within the circular boundary */
}

.active-menu span {
  color: black;
  text-decoration: none;
}

.inactive-menu span {
  /* background-color: #FF914D; */
  color: white;
  text-decoration: none;
}

.active {
  background-color: #ff914d;
  border-width: 0;
  width: 100px;
  height: 40px;
}

.tab {
  background-color: white;
  border-width: 0;
  width: 100px;
  height: 40px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* Adjust the height as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  animation: spin 1s linear infinite;
  /* Apply animation */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.Profile-container {
  width: 230px;
  height: 80px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.detailmain {
  background-color: white;
  display: flex;
  height: 100%;
  width: 80%;
  position: absolute;
  flex-direction: column;
  left: 20%;
  padding-left: 20px;
}

.custom-map-modal {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
}

.headerDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15;
}

.chat_users_list {
  background-color: #f3f0f0;
  width: 368px;
  height: 90cqh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chat-list {
  height: 70px;
  width: 100%;
  align-items: center;
  padding: 6px;
}

.chat_list_input {
  background-color: #f3f0f0;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  height: 90cqh;
}

.profile_Setup {
  height: 70px;
  width: 100%;
  align-items: center;
  padding: 6px;
  background-color: #f3f0f0;
}

.profile_Setup .rce-container-citem {
  background-color: #f3f0f0;
}

.write_message {
  width: 100%;
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  margin-top: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-width: 0px;
}

.message_Input {
  border-width: 0px;
  border-color: white;
  outline: none;
  width: 90%;
  margin-bottom: 10px;
}

.chat_container {
  width: 100%;
  /* Adjust width as needed */
  height: 80%;
  /* Adjust height as needed */
  overflow-x: scroll;
  /* Add vertical scrollbar */
  border: 1px solid #ccc;
  border-width: 0px;
}

.message-image {
  width: 250px;
  height: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.first_division {
  width: 28%;
  height: 100%;
}

.second_division {
  width: 35%;
  height: 100%;
  margin: 20px;
}

.third_division {
  width: 65%;
  height: 100%;
  padding: 20px;
}
.documentImage {
  width: 70%;
}
.deposite_division{
  width: 100%;
  height: 100%;
  padding: 20px;
 
}
.Deposite_List{
  width: 100%;
  height: 100%;
  
 
}
.deposite_division{
  width: 100%;
  height: 100%;
  padding: 20px;
 
}
.Deposite_List{
  width: 100%;
  height: 100%;
}
